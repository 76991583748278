import { Injectable } from '@angular/core'
import {
  AdobeAnalyticsParams,
  AdobeEventTypes,
  MemberValidationType,
  BusinessLineProduct
} from '../tagging.types'
import { AdobeAnalyticsService } from './adobe-analytics.service'
import { getAppId } from '../../../shared/utils/cookies'
import { Store, select } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { selectUserSessionId } from '../../session/session.selectors'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class AdobeMemberValidationService {
  businessLine = 'Automotive'
  businessLineProduct = BusinessLineProduct.DIGITAL_ERS

  constructor(
    private adobeAnalyticsService: AdobeAnalyticsService,
    private store$: Store<AAAStore>,
  ) { }

  sendEvent(
    eventType:
      AdobeEventTypes.MEMBER_VALIDATION |
      AdobeEventTypes.MEMBER_VALIDATION_PROMPT |
      AdobeEventTypes.MEMBER_VALIDATION_TOGGLE |
      AdobeEventTypes.MEMBER_VALIDATION_RETURN,
    memberValidationType: MemberValidationType,
    directAttributes?: {}
  ) {
    const params$ = this.getMemberValidationParams(eventType, memberValidationType, directAttributes)

    params$.subscribe(params => {
      try {
        this.adobeAnalyticsService.transmitAdobeAnalyticsEvents(params)
      } catch (error) {
        console.warn('Error transmitting Adobe Analytics events', error)
      }
    })
  }

  private getMemberValidationParams(
    eventType:
      AdobeEventTypes.MEMBER_VALIDATION |
      AdobeEventTypes.MEMBER_VALIDATION_PROMPT |
      AdobeEventTypes.MEMBER_VALIDATION_TOGGLE |
      AdobeEventTypes.MEMBER_VALIDATION_RETURN,
    memberValidationType: MemberValidationType,
    directAttributes?: {}
  ): Observable<AdobeAnalyticsParams> {
    return this.store$.pipe(
      select(selectUserSessionId),
      take(1),
      map((sessionId: string) => ({
        eventName: eventType,
        directAttributes: {
          ...directAttributes,
          member_validation_type: memberValidationType,
          business_line: this.businessLine,
          business_line_product: this.businessLineProduct,
          app_id: getAppId(),
          session_id: sessionId
        }
      })
      )
    )
  }

}
