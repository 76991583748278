import { Injectable } from '@angular/core'
import { GoogleAnalyticsParams, AdobeAnalyticsParams, AdobeEventTypes, ProductCategory, ADOBE_EVENT_PROPS, AdobeEventData } from '../tagging.types'
import { capitalize } from '../../../shared/utils/capitalize'
import { AdobeAnalyticsService } from './adobe-analytics.service'
import { AppId } from '../../auth/auth.types'
import { getProductCategory } from './utils'
import { PACE_SETTER_OVERRIDE_TYPES } from '../../servicing-club/servicing-club.types'
import { PACE_SETTER_SITUATION_TYPES } from '../../issue/issue.types'


@Injectable({
  providedIn: 'root',
})
export class AdobeEventService {
  constructor(private adobeAnalyticsService: AdobeAnalyticsService) { }

  sendEvent(
    eventData: AdobeEventData,
    issue_type?: string,
    directAttributes?: {}
  ) {
    const params = this.getEventParams(eventData, issue_type, directAttributes)
    try {
      this.adobeAnalyticsService.transmitAdobeAnalyticsEvents(params)
    } catch (error) {
      console.error('Error transmitting Adobe Analytics events', error)
    }
  }

  mapEvent({ action, appId }: GoogleAnalyticsParams): AdobeAnalyticsParams {
    return {
      eventName: AdobeEventTypes.CLICK,
      eventAttributes: {
        click_value: action,
        product_category: getProductCategory(appId as AppId) as ProductCategory,
      }
    }
  }

  private getEventParams(
    eventData: AdobeEventData,
    issue_type: string,
    directAttributes: {}): AdobeAnalyticsParams {

    const eventPropKey = ADOBE_EVENT_PROPS[eventData.eventName]
    return  {
      eventName: eventData.eventName,
      directAttributes: {
        [eventPropKey]: capitalize(eventData.eventValue.trim()),
        ...directAttributes,
        ...(issue_type ? { issue_type: capitalize(issue_type) } : {})
      }
    }
  }

  mapSubIssueSelectionEvent(paceSetterOverrideKey: PACE_SETTER_OVERRIDE_TYPES, paceSetterCode?: string) {
    const actionData = {
      cta_value: '',
      issue_type: '',
      pacesetter_code: paceSetterCode
    }
    switch (paceSetterOverrideKey) {
      // FLAT TIRES
      case PACE_SETTER_OVERRIDE_TYPES.TIRE_SPARE:
        actionData.cta_value = 'Spare Tire'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.FLAT_TIRE
        break
      case PACE_SETTER_OVERRIDE_TYPES.TIRE_NO_SPARE:
        actionData.cta_value = 'No Spare Tire'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.FLAT_TIRE
        break

      // FUEL/EV CHARGE
      case PACE_SETTER_OVERRIDE_TYPES.FUEL_GAS:
        actionData.cta_value = 'Fuel Type Selection: Gasoline'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL
        break
      case PACE_SETTER_OVERRIDE_TYPES.FUEL_DIESEL:
        actionData.cta_value = 'Fuel Type Selection: Diesel'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL
        break
      case PACE_SETTER_OVERRIDE_TYPES.FUEL_ELECTRIC:
        actionData.cta_value = 'Fuel Type Selection: Electric'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL
        break
      case PACE_SETTER_OVERRIDE_TYPES.FUEL_HYDROGEN_ALT:
        actionData.cta_value = 'Fuel Type Selection: Hydrogen/Alternate'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.OUT_OF_FUEL
        break

      // LOCKED OUT
      case PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_INSIDE:
        actionData.cta_value = 'Key Locked In Vehicle'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.LOCKED_OUT
        break
      case PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_LOST:
        actionData.cta_value = 'Key Is Broken Or Lost'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.LOCKED_OUT
        break
      case PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_NO_TURN:
        actionData.cta_value = 'Key Won\'t Turn In Ignition'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.LOCKED_OUT
        break
      case PACE_SETTER_OVERRIDE_TYPES.LOCK_KEY_NO_WORK:
        actionData.cta_value = 'Key Won\'t Unlock Door'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.LOCKED_OUT
        break
      case PACE_SETTER_OVERRIDE_TYPES.LOCK_UNSURE:
        actionData.cta_value = 'Don\'t Know Where Keys Are'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.LOCKED_OUT
        break

      // STUCK
      case PACE_SETTER_OVERRIDE_TYPES.STUCK:
        actionData.cta_value = 'Tow Needed'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.STUCK
        break
      case PACE_SETTER_OVERRIDE_TYPES.STUCK_NO_TOW:
        actionData.cta_value = 'No Tow Needed'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.STUCK
        break

      // CAR WON'T START
      case PACE_SETTER_OVERRIDE_TYPES.WONT_START_DIED:
        actionData.cta_value = 'Vehicle Died While Driving'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.CAR_WONT_START
        break
      case PACE_SETTER_OVERRIDE_TYPES.WONT_START:
        actionData.cta_value = 'Nothing Happens on Key Turn'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.CAR_WONT_START
        break
      case PACE_SETTER_OVERRIDE_TYPES.WONT_START_CLICK:
        actionData.cta_value = 'Clicking Sound on Key Turn'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.CAR_WONT_START
        break
      case PACE_SETTER_OVERRIDE_TYPES.WONT_START_CRANK:
        actionData.cta_value = 'Car Cranks But Won’t Start on Key Turn'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.CAR_WONT_START
        break
      case PACE_SETTER_OVERRIDE_TYPES.WONT_START_BATT:
        actionData.cta_value = 'Battery Issue'
        actionData.issue_type = PACE_SETTER_SITUATION_TYPES.CAR_WONT_START
        break
    }

    return actionData
  }

}
