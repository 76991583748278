import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, fromEvent, Observable } from 'rxjs'
import { debounceTime, filter, map } from 'rxjs/operators'
import { aarAppendLocationMarker, convertToLocationMarker, isAddressComplete, LocationUtils } from '../location.utils';

import {
  AAALocation,
  GenericCoordinates,
  GoogleLocationMarker,
  LastSearchLocation,
  MapState,
  SearchViews,
} from '../location.types'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import { isDeepCopy } from '../../../shared/utils/isDeepCopy'
import { AAAStore } from '../../../store/root-reducer'
import { requestRouteDistance } from '../../route-distance/route-distance.actions'
import events from '../../tagging/events'
import { TaggingService } from '../../tagging/tagging.service'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { AAR_LOAD, requestSearchArea, resetAar, setAarPreview, setHasRatingSummary } from '../aar/aar.actions'
import {
  selectAarPreview,
  selectAARsCustomMessage,
  selectDestinationType,
  selectFacility,
  selectLastTowSearchArea,
  selectSortedFacilitiesDisplay,
  selectSuggestedShop,
  selectTowLocationSearch,
} from '../aar/aar.selectors'
import { AARData, FacilitiesDisplay } from '../aar/aar.types'
import { GOOGLE_LOCATION_TYPES, GoogleCoordinates, } from '../google-geocode/types'
import { selectBreakdownLocationCoordinates, selectBreakdownMarker, } from '../location.selectors'
import {
  requestAddressLookup,
  requestCoordsLookup,
  requestHomeAddressLookup,
  resetTowDestination,
  setAARAddress,
  setLastTowToSearchLocation,
} from './tow-location.actions'
import {
  selectAllowCustomTowDestination,
  selectLastTowToSearchLocation,
  selectTowingStep,
  selectTowLocationMarker,
  selectTowLocationPreviewAddress,
  selectTowLocationPreviewMarker,
} from './tow-location.selectors'
import { RapService } from '../../rap/rap.service'
import { Title } from '@angular/platform-browser'
import { buildTitle } from '../../../shared/utils/title'
import { cancelStepsAhead, openPromptDialog } from '../../ui/ui.actions'
import { isIOS } from '../../../shared/utils/browser-detect'
import { PromptDialogTypes, TowSections } from '../../ui/ui.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service';
import { AdobeEventTypes } from '../../tagging/tagging.types';
import { DestinationType } from '../../../shared/types';
import { Router } from '@angular/router';
import { WizardService } from '../../wizard/wizard.service';
import { EditService } from '../../../shared/services/edit.service';

const TITLE_TOW_LOCATION = () => $localize`Tow Location`
const HEADER_CHARGED = () => $localize`Now let's select where your vehicle will be charged:`
const SUB_HEADER_CHARGED = () => $localize`Please select an EV charging location:`
const HEADER_TOWED = () => $localize`Now let's select where your vehicle will be towed to:`
const SUB_HEADER_TOWED = () => $localize`AAA Shops provide:`
const SUB_HEADER_SEARCH_EV = () => $localize`EV stations:`
const HEADER_SEARCH_ALLOW_CUSTOM = () => $localize`Or search for`
const SUB_HEADER_SEARCH_DESTINATION = () => $localize`a destination:`
const SHOP_ITEMS = () => [
  $localize`Quality and Reliable Service`,
  $localize`Expert Staff & Mechanics`,
  $localize`Member Discounts`,
]
const TITLE_PASSENGERS = () => $localize`Passengers`

@Component({
  selector: 'app-tow-location-shop-list-top',
  templateUrl: './tow-location.component.html',
  styleUrls: ['./tow-location.component.scss'],
})
export class TowLocationComponent extends AbstractResponsiveComponent implements OnInit {
  @ViewChild('towLocationBody') towLocationBody: ElementRef
  SearchViewsType = SearchViews

  step$ = this.store$.pipe(select(selectTowingStep))

  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(AAR_LOAD.ACTION))
  )

  BTN_SHOW_MIN_SCROLL = 50
  shouldDisplayMapBtn$ = fromEvent(window as Window, 'scroll').pipe(
    debounceTime(100),
    map(() => window.scrollY > this.BTN_SHOW_MIN_SCROLL)
  )

  showMapModal: Boolean = false

  suggestedShop: AARData
  suggestedShop$: Observable<AARData> = this.store$.pipe(
    select(selectSuggestedShop)
  )

  breakdownCoordinates
  breakdownCoordinates$: Observable<GenericCoordinates> = this.store$.pipe(
    select(selectBreakdownLocationCoordinates)
  )
  towLocationSearch$: Observable<MapState> = this.store$.pipe(
    select(selectTowLocationSearch)
  )
  breakdownMarker$: Observable<GoogleLocationMarker> = this.store$.pipe(
    select(selectBreakdownMarker),
    filter((marker) => Boolean(marker))
  )
  towLocationSearch = null
  destinationShop = null
  showSeeMoreButton = false
  isSeeFacilitiesClicked$ = new BehaviorSubject<boolean>(false)

  facilitiesDisplay$: Observable<FacilitiesDisplay> = this.store$.pipe(
    select(selectSortedFacilitiesDisplay)
  )

  facilitiesDisplayToMap$ = combineLatest([
    this.facilitiesDisplay$,
    this.isSeeFacilitiesClicked$
  ])
    .pipe(
      map(([facilitiesDisplay, isSeeFacilitiesClicked]) => {
        if (this.selectedShop?.id) {
          return this.destinationShop
        } else if (isSeeFacilitiesClicked) {
          return facilitiesDisplay
        }
      })
    )

  aarPreview$: Observable<number | null> = this.store$.pipe(
    select(selectAarPreview)
  )
  towLocationMarkers$: Observable<
    [GoogleLocationMarker, GoogleLocationMarker, any]
  > = combineLatest([
      this.store$.pipe(select(selectTowLocationMarker)),
      this.store$.pipe(select(selectTowLocationPreviewMarker)),
      this.store$.pipe(select(selectFacility)),
    ])
  aarsCustomMessage$: Observable<boolean> = this.store$.pipe(
    select(selectAARsCustomMessage)
  )
  allowCustomTowDestination$: Observable<boolean> = this.store$.pipe(
    select(selectAllowCustomTowDestination)
  )
  destinationType$: Observable<DestinationType> = this.store$.pipe(
    select(selectDestinationType)
  )
  destinationType: DestinationType
  isEV = false

  isAddressValid = false
  towLocationValue = null
  get towLocation(): GoogleLocationMarker {
    return this.towLocationValue
  }

  isRapUser = this.rapService.isRapUser()
  allowCustomTowDestination: boolean

  lastSearchLocation$: Observable<LastSearchLocation> = this.store$.pipe(
    select(selectLastTowToSearchLocation)
  )

  selectedShop: AARData

  set towLocation(towLocation: GoogleLocationMarker) {
    if (!isDeepCopy(towLocation, this.towLocationValue)) {
      this.towLocationValue = towLocation || null
      this.isAddressValid =
        towLocation &&
        towLocation.hasOwnProperty('lat') &&
        towLocation.hasOwnProperty('lng')
    }
    this.sendPageLoadEvent()
  }

  towLocationPreviewAddress$: Observable<AAALocation> = this.store$.pipe(
    select(selectTowLocationPreviewAddress)
  )

  aarSelector = null
  shopPreviewId: number

  constructor(
    protected store$: Store<AAAStore>,
    protected locationUtils: LocationUtils,
    protected tagging: TaggingService,
    protected adobeEventService: AdobeEventService,
    protected router: Router,
    protected wizardService: WizardService,
    protected editService: EditService,
    private rapService: RapService,
    private titleService: Title,
    private elementRef: ElementRef
  ) {
    super()
    if (!this.isRedesign) {
      this.titleService.setTitle(
        buildTitle(TITLE_TOW_LOCATION(), this.rapService.isRapUser())
      )
      this.tagging.setPageEvent(
        events.towTo.DESTINATION_PAGE_PROMPT,
        events.towTo.DESTINATION_PAGE_TYPE
      )
    }
  }

  lastSearchLocation
  lastSearchLocationArea$: Observable<GenericCoordinates> = this.store$.pipe(
    select(selectLastTowSearchArea)
  )

  ngOnInit() {
    isIOS() && this.setAvailableHeight()
    this.subscriptions.push(
      this.breakdownCoordinates$.subscribe((coordinates) => {
        this.breakdownCoordinates = coordinates
      }),
      this.lastSearchLocationArea$.subscribe((lastSearchLocation) => {
        this.lastSearchLocation = lastSearchLocation
      }),
      this.towLocationMarkers$.subscribe(([towLocation, preview, aarSelector]) => {
        this.towLocation = preview || towLocation || null
        this.aarSelector = aarSelector
        if (this.towLocation?.aarId) {
          this.selectedShop = aarSelector(this.towLocation.aarId)
          const marker = convertToLocationMarker(this.selectedShop)
          if (marker) {
            this.destinationShop = {
              markers: [marker],
            }
          }
        } else {
          this.selectedShop = null
        }
      }),
      this.allowCustomTowDestination$.subscribe((allowCustomTowDestination) => this.allowCustomTowDestination = allowCustomTowDestination),
      this.facilitiesDisplay$.subscribe((facilityDisplay) => {
        const hasRatingSummary = Boolean(facilityDisplay.aarData.find((data) => Boolean(data?.ratingSummary)))
        this.store$.dispatch(setHasRatingSummary({ payload: hasRatingSummary }))
        this.showSeeMoreButton = facilityDisplay.aarData.length > 5
      }),
      this.towLocationSearch$.subscribe((towLocationSearch) =>
        this.towLocationSearch = towLocationSearch
      ),
      this.aarPreview$.subscribe((aarPreview) => {
        this.shopPreviewId = aarPreview
      }),
      this.suggestedShop$.subscribe(aar => this.suggestedShop = aar),
      this.destinationType$.subscribe(destinationType => {
        this.destinationType = destinationType
        this.isEV = destinationType === DestinationType.EV_STATION
      }),
      this.towLocationPreviewAddress$.subscribe((preview) => {
        const isTowAddressIncomplete = preview?.address && !isAddressComplete(preview)
        if (isTowAddressIncomplete) {
          this.isSeeFacilitiesClicked$.next(true)
        }
      }),
      this.step$.subscribe((step) => {
        if (this.isRedesign) {
          if (step === TowSections.PASSENGERS) {
            this.titleService.setTitle(buildTitle(TITLE_PASSENGERS(), this.rapService.isRapUser()))
            this.tagging.setPageEvent(
              events.towTo.PASSENGERS_PAGE_PROMPT,
              events.towTo.PASSENGERS_PAGE_TYPE
            )
          } else {
            this.titleService.setTitle(buildTitle(TITLE_TOW_LOCATION(), this.rapService.isRapUser()))
            this.tagging.setPageEvent(
              events.towTo.DESTINATION_PAGE_PROMPT,
              events.towTo.DESTINATION_PAGE_TYPE
            )
          }
        }
      })
    )
  }

  setAvailableHeight = () => {
    const hostElement = this.elementRef.nativeElement as HTMLElement;
    const availableSpace = document.documentElement.offsetHeight - document.documentElement.clientHeight;
    hostElement.style.setProperty('--component-height', `calc(100% - ${availableSpace}px)`);
  }

  handleAddressSelected(address) {
    if (address?.description) {
      this.store$.dispatch(
        requestAddressLookup({
          payload: {
            address: address.description,
            ...(address.landmark ? {landmark: address.landmark} : {})
          }})
      )
      this.store$.dispatch(setLastTowToSearchLocation({ payload: address }))
      this.store$.dispatch(resetAar())
    } else {
      this.store$.dispatch(resetTowDestination())
      this.isSeeFacilitiesClicked$.next(false)
      this.store$.dispatch(cancelStepsAhead())
      this.store$.dispatch(requestSearchArea({
        payload: {
          center: this.breakdownCoordinates
        }
      }))
    }
  }

  handleMapClick(coords: GoogleCoordinates) {
    this.store$.dispatch(
      requestCoordsLookup({
        payload: { ...coords },
        meta: {
          preferredType: GOOGLE_LOCATION_TYPES.ROOFTOP,
        },
      })
    )
  }

  handleNext() {
    if (
      (this.isAddressValid || !this.isMobile) &&
      this.breakdownCoordinates &&
      this.breakdownCoordinates.latitude &&
      this.breakdownCoordinates.longitude
    ) {
      if (!this.isMobile && this.shopPreviewId) {
        const shop = aarAppendLocationMarker(this.aarSelector(this.shopPreviewId))
        if (shop) {
          this.store$.dispatch(
            setAARAddress({
              payload: shop,
            })
          )
        }
      }
      this.store$.dispatch(
        requestRouteDistance({
          payload: {
            origin: this.breakdownCoordinates,
            destination: {
              ...(this.towLocation.aarId ? { id: this.towLocation.aarId } : {}),
              latitude: this.towLocation.lat,
              longitude: this.towLocation.lng,
            },
            checkTowDistance: true,
          },
        })
      )
    }
  }

  handleSeeFacilitiesClick(value: boolean) {
    this.isSeeFacilitiesClicked$.next(value)
  }

  setHomeLocation() {
    this.store$.dispatch(requestHomeAddressLookup())

    this.tagging.setClickEvent(
      events.towTo.DESTINATION_HOME_CLICK,
      events.towTo.DESTINATION_PAGE_TYPE
    )
  }

  getHeadingTitle() {
    const _message = this.isEV ? HEADER_CHARGED() : HEADER_TOWED()

    let _subMessage
    switch (this.destinationType) {
      case DestinationType.EV_STATION:
        _subMessage = SUB_HEADER_CHARGED()
        break
      case DestinationType.AAR:
        _subMessage = SUB_HEADER_TOWED()
        break
      default:
        _subMessage = ''
    }

    return `<strong>${_message}</strong>
    <p>${_subMessage}</p>
    `
  }

  getShopItems() {
    return SHOP_ITEMS()
  }

  getHeadingSubtitle() {
    const _subMessage = this.isEV ? SUB_HEADER_SEARCH_EV() : SUB_HEADER_SEARCH_DESTINATION()
    return `${HEADER_SEARCH_ALLOW_CUSTOM()}
      <strong> ${_subMessage} </strong>
      `
  }

  _scrollToList() {
    setTimeout(() => {
      this.towLocationBody.nativeElement.scrollIntoView({
        behavior: 'smooth',
      })
    }, 150)
  }
  onAarBannerClick() {
    this._scrollToList()
  }

  showMap(show: boolean) {
    this.showMapModal = show
    this.sendPageLoadEvent()
  }

  dialogShopDetails = {
    type: PromptDialogTypes.SHOP_DETAILS,
    close: () => this.sendPageLoadEvent(),
  }
  handleSuggestedShopClick() {
    this.store$.dispatch(setAarPreview({payload: {id: this.suggestedShop.id}}))
    const shop = aarAppendLocationMarker(this.suggestedShop)
    if (this.isMobile && shop) {
      this.store$.dispatch(
        setAARAddress({
          payload: shop,
        })
      )
    } else {
      this.store$.dispatch(
        openPromptDialog({
          payload: {
            ...this.dialogShopDetails,
            params: { id: this.suggestedShop.id },
          }
        })
      )
    }
    this.tagging.setClickEvent(
      events.towTo.DESTINATION_SUGGESTED_SHOP_CLICK,
      events.towTo.DESTINATION_PAGE_TYPE
    )
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.DESTINATION_SUGGESTED_SHOP_CLICK,
    })
  }

  handleSeeMore(event) {
    event.preventDefault()
    if (this.isMobile) {
      this.showMap(true)
    }
    this.tagging.setClickEvent(
      events.towTo.DESTINATION_SEE_MORE_SUGGESTIONS_CLICK,
      events.towTo.DESTINATION_PAGE_TYPE
    )
    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.DESTINATION_SEE_MORE_SUGGESTIONS_CLICK,
    })
  }

  handleSeeMoreList() {
    this.showSeeMoreButton = false

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: events.towTo.DESTINATION_SEE_MORE_FACILITIES_LIST
    })
  }

  autoCompleteBackButton() {
    this.sendPageLoadEvent(true)
  }

  lastPageName
  sendPageLoadEvent(force?: boolean) {
    let pageName = this.isAddressValid || (!this.isMobile && this.shopPreviewId) ? events.towTo.PAGE_NAME_CONFIRM_TOW_LOCATION : events.towTo.PAGE_NAME_TOW_TO_LIST
    if (this.showMapModal) {
      pageName = events.towTo.PAGE_NAME_TOW_TO_MAP
    }
    if(force || pageName !== this.lastPageName) {
      this.tagging.setPageLoadEvent({ pageType: events.towTo.PAGE_TYPE, pageName })
    }
    this.lastPageName = pageName
  }
}
